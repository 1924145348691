import * as moment from 'moment'


export function currentTime(date) {
  return moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
}

export function dateTimeFormat(date) {
 return moment(date, ["DD.MM.YYYY", "YYYY-MM-DD"]).format("DD-MM-YYYY");
  // return moment(date).format("YYYY-MM-DD");
}
export function dateFormat(date) {
  const data = new Date(date)
  let d = moment(data).format("DD-MM-YYYY");
  return d
}


export function setEndTime(date, seconds) {
  var startdate = moment(date);
  return moment(startdate).add(seconds, 'seconds').format("YYYY-MM-DD HH:mm:ss");
}

export function timeFormat(date) {
  return moment(date).format("HH:mm:ss");
}
export function timeDiff(startTime) {
  //   const time  = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
  //  return time.diff(startTime, 'seconds');

  var a = moment();
  // var b = moment().add(1, 'seconds');
  return a.diff(startTime, 'minutes') // -1000
  // return duration.asSeconds();
}
export class MyDate {
  newDate


  constructor(date) {
    if (date) {
      this.newDate = new Date(date);
    } else {
      this.newDate = new Date();
    }
  }

  getFullYear() {
    return this.newDate.getUTCFullYear();
  }

  getMonth() {
    return this.newDate.getUTCMonth();
  }

  getDate() {
    return this.newDate.getUTCDate();
  }
}