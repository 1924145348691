<template>
  <!-- home survey -->
  <!-- <div class="survey">
        <div class="main-design">
            <div class="topImg">
              <img src="../assets/images/top.png" alt="top" class="img-fluid topDesign">
            </div>
        <div class="container" style="margin-top: 50px;">
            <div class="content">
                <div class="dream-text">
                    <h1 class="mb-0">Survey</h1>
                    <p>Lorem ipsum dolor sit amet <br> ipsum dolor sit amet</p>
                </div>
            </div>
            <div class="loginForm" style="margin:0 30px 30px 30px">
                <div class="loginForm__input">
                    <label for="fname">Enter Your Mobile Number</label><br />
                    <input type="text">
                </div>
            </div>
            <div class="get_start">
                <router-link to="/login"><button class="getBtn">Get started Now</button></router-link>
            </div>
        </div>
        <div class="bottomImg">
        </div>
      </div>
    </div> -->

  <!-- Questions -->
  <!-- <div class="gray-theme" style="position: relative;"> -->
  <div class="main" style="position: relative;">
    <!-- <div class="topImg">
      <img
        src="../assets/images/top.png"
        alt="top"
        class="img-fluid topDesign"
      />
    </div> -->
    <div class="themeInnerWrapper">
      <div class="container mb-4" :class="templateTheme && templateTheme.theme_class != '' ? templateTheme.theme_class : ''">
        <div
          class="content content-page survey-sys content-loder d-flex flex-column justify-content-between"
          v-if="
            taskDetails && taskDetails.sub_task && taskDetails.sub_task.length > 1
          "
        >
        <div>
          <div class="button_skip">
            <button type="button"   v-if="questionDetail.form.required ? true : false "  @click="submitQuestionFeedback(1)">Skip
              <span><i class="zmdi zmdi-chevron-right"></i><i class="zmdi zmdi-chevron-right"></i></span>
            </button>
          </div>
          <div class="button_back" v-if="subTasks.length > 1 && questionIndex > 0">
            <button type="button" @click="getPreviousQuestion()"><i class="zmdi zmdi-arrow-left"></i></button>
          </div>
          <div class="questionBoxWrapper">
              <div v-if="questionDetail.form.type == 'long_text'">
                <div class="phone-angle que">
                  <h4>{{ questionIndex + 1 }} / {{ interviewQuestionCount }}</h4>
                  <!-- <h2>Question</h2> -->
                  <p class="question-title mb-2">
                    {{ questionDetail.form.label }} <span class="qsn_required"  v-if="questionDetail.form.required ? true : false " > * </span>
                  </p>
                  <!-- <p class="type-que">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident non accusamus iste nisi et architecto in ratione quasi ea dolore.</p> -->
                </div>
                <div class="que-input mt-3 optionBox">
                  <h3 class="mb-2">Answer</h3>
                  <textarea
                    id=""
                    cols="30"
                    rows="10"
                    :name="questionDetail.form.name"
                    :placeholder="questionDetail.form.placeholder"
                    v-model="collabFeedbackForm.field_value"
                    @change="submitQuestion()"
                    v-validate="questionDetail.form.required ? 'required' : ''"
                    :data-vv-name="questionDetail.form.name"
                  ></textarea>
                  <Loader :show-loader="isDataLoading" :isFullPage="false" />
                  <p
                    class="r-field"
                    v-show="errors.has(questionDetail.form.name)"
                    style="color: #D93F3F; font-size: 12px;display:block"
                    >This field is required</p
                  >
                </div>
              </div>
              <div v-if="questionDetail.form.type == 'drop_down'">
                <div class="phone-angle que">
                  <h4>{{ questionIndex + 1 }} / {{ interviewQuestionCount }}</h4>
                  <!-- <h2>Question</h2> -->
                  <p class="question-title mb-2">
                    {{ questionDetail.form.label }} <span class="qsn_required"  v-if="questionDetail.form.required ? true : false " > * </span>
                  </p>
                  <!-- <p class="type-que">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Provident non accusamus iste nisi et architecto in ratione quasi ea dolore.</p> -->
                </div>
                <form class="form-data">
                  <div class="que-input select-opt mt-4 optionBox">
                    <h3 class="mb-2">Answer</h3>
                    <select
                      class="select-droplist select-ans form-control"
                      @change="submitQuestion()"
                      v-model="collabFeedbackForm.field_value"
                      :name="questionDetail.form.name"
                      v-validate="questionDetail.form.required ? 'required' : ''"
                      :data-vv-name="questionDetail.form.name"
                    >
                      <option value=" ">Select</option>
                      <option
                        v-for="(drop_item, drop_index) in questionDetail.form
                          .options.list"
                        :key="drop_index"
                        :value="drop_item.value"
                        class="option-bck"
                      >
                        {{ drop_item.value }}
                      </option>
                    </select>
                    <!-- <v-select :options="options"></v-select> -->
                    <Loader :show-loader="isDataLoading" :isFullPage="true" />
                  </div>
                  <p
                    class="r-field"
                    v-show="errors.has(questionDetail.form.name)"
                    style="color: #D93F3F; font-size: 12px"
                    >This field is required</p
                  >
                </form>
              </div>
              <div
                class="que-input mt-4"
                v-if="questionDetail.form.type == 'radio'"
              >
                <h4>{{ questionIndex + 1 }} / {{ interviewQuestionCount }}</h4>
                <!-- <h2>Question</h2> -->

                <h6 class="question-title mb-2">{{ questionDetail.form.label }} <span class="qsn_required"  v-if="questionDetail.form.required ? true : false " > * </span></h6>
                <div
                  class="preTask"
                  v-for="(data, rad_index) in questionDetail.form.options.list"
                  :key="rad_index"
                >
                  <label class="containerBox mb-0 ">
                    <p class="option">{{ data.text }}</p>
                    <input
                      type="radio"
                      :disabled="disabled"
                      :value="data.value"
                      :data-vv-name="questionDetail.form.name"
                      :error-messages="errors.collect(questionDetail.form.name)"
                      v-validate="questionDetail.form.required ? 'required' : ''"
                      v-model="collabFeedbackForm.field_value"
                      :name="questionDetail.form.name"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <Loader :show-loader="isDataLoading" :isFullPage="false" />
                <p
                  class="r-field"
                  v-show="errors.has(questionDetail.form.name)"
                  style="color: #D93F3F; font-size: 12px"
                  >This field is required</p
                >
              </div>

              <div
                class="que-input mt-4"
                v-if="questionDetail.form.type == 'multiple_choice'"
              >
                <h4>{{ questionIndex + 1 }} / {{ interviewQuestionCount }}</h4>
                <!-- <h2>Question</h2> -->

                <h6 class="question-title mb-2">{{ questionDetail.form.label }} <span class="qsn_required"  v-if="questionDetail.form.required ? true : false " > * </span></h6>
                <div class="optionBox">
                  <div
                  class="square-input"
                  v-for="(data, rad_index) in questionDetail.form.options.list"
                  :key="rad_index"
                >
                  <label class="containerBox mb-0 ">
                    <p class="option mb-0">{{ data.value }}</p>
                    <input
                      type="checkbox"
                      class="new_mit mark_mit"
                      :name="questionDetail.form.name + rad_index"
                      v-model="multiple_choice_arr"
                      :value="data.value"
                      v-validate="questionDetail.form.required ? 'required' : ''"
                      :data-vv-name="questionDetail.form.name"
                    />
                    <div class="checkmark"></div>
                  </label>
                </div>
                </div>
                <Loader :show-loader="isDataLoading" :isFullPage="false" />
                <p
                  class="r-field"
                  v-show="errors.has(questionDetail.form.name)"
                  style="color: #D93F3F; font-size: 12px"
                  >This field is required</p
                >
              </div>

              <div
                class="que-input mt-4 rating"
                v-if="questionDetail.form.type == 'rating_1_to_5'"
              >
                <h4>{{ questionIndex + 1 }} / {{ interviewQuestionCount }}</h4>
                <!-- <h2>Question</h2> -->
                <p class="question-title mb-2">{{ questionDetail.form.label }} {{defaultDate}}<span class="qsn_required"  v-if="questionDetail.form.required ? true : false " > * </span></p>

                <star-rating
                  :rating="rating"
                  :increment="1"
                  :max-rating="5"
                  :show-rating="false"
                  :star-size="40"
                  :text-class="'star-rated'"
                  @rating-selected="setRating"
                />

                <Loader :show-loader="isDataLoading" :isFullPage="false" />
              </div>
              <div
                class="que-input mt-4"
                v-else-if="questionDetail.form.type == 'date'"
              >
                <div class="birth-day">
                  <h4>{{ questionIndex + 1 }} / {{ interviewQuestionCount }}</h4>
                  <!-- <h2>Question</h2> -->
                  <p class="question-title mb-2">{{ questionDetail.form.label }} <span class="qsn_required"  v-if="questionDetail.form.required ? true : false " > * </span></p>
                  <!-- <p class="type-que">Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci corrupti vel ipsam similique! Modi sed deserunt assumenda mollitia odit sequi?</p> -->
                  <div class="dob mb-3 optionBox">
                    <div class="date-picker d-inline-block">
                      <!-- <h5>Date Of Birth</h5> -->
                      <!-- <date-dropdown
                        :default="defaultDate"
                        min="1960"
                        max="2035"
                        v-model="collabFeedbackForm.field_value"
                      /> -->

                      <datepicker :placeholder="questionDetail.form.placeholder != '' ? questionDetail.form.placeholder : ' Please select date'" :format="'d MMMM yyyy'"
                          v-model="collabFeedbackForm.field_value"
                          minDate="defaultDate"
                          :value="defaultDate"
                          v-validate="questionDetail.form.required ? 'required' : ''"
                          :data-vv-name="questionDetail.form.name"
                         
                          :required="questionDetail.form.required ? true : ''" :calendar-button="true" >
                      </datepicker>
                        <p
                          class="r-field"
                          v-show="errors.has(questionDetail.form.name)"
                          style="color: #D93F3F; font-size: 12px"
                          >This field is required</p
                        >
                    </div>
                    <!-- <h3>You are 26 years old.</h3> -->
                  </div>
                </div>
                <Loader :show-loader="isDataLoading" :isFullPage="false" />
              </div>

              <div class="que-input mt-4" v-else-if="questionDetail.form.type == 'toggle'">
                <div class="thought">
                  <h4>{{ questionIndex + 1 }} / {{ interviewQuestionCount }}</h4>
                  <!-- <h2>Question</h2> -->
                  <p class="question-title mb-2">{{ questionDetail.form.label }} <span class="qsn_required" v-if="questionDetail.form.required ? true : false " > * </span></p>
                  <!-- <p class="type-que">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium, assumenda. What is your thought about it</p> -->
                  <div class="mt-1 optionBox">
                    <div class="thought-data">
                        <label class="switch mb-0 mr-3">
                          <!-- <input
                            type="checkbox"
                            id="is_completed"
                            :name="questionDetail.form.name"
                            :data-vv-name="questionDetail.form.name"
                            :error-messages="
                              errors.collect(questionDetail.form.name)
                            "
                            v-validate="
                              questionDetail.form.required ? 'required' : ''
                            "
                            v-model="collabFeedbackForm.field_value"
                            @change="updateToggleStatus()"
                            class="show_comment is_completed"
                          /> -->
                          <input
                            type="checkbox"
                            id="is_completed"
                            :name="questionDetail.form.name"
                            v-model="collabFeedbackForm.field_value"
                            @change="submitQuestion()"
                            class="show_comment is_completed"
                          />
                          <span class="slider round"></span>
                        </label>
                        <h5 class="mt-0">IT'S TRUE</h5>
                    </div>
                  </div>
                </div>
                <Loader :show-loader="isDataLoading" :isFullPage="false" />
              </div>
              <div
                class="que-input mt-4"
                v-else-if="questionDetail.form.type == 'short_text'"
              >
                <div class="choose">
                  <h4>{{ questionIndex + 1 }} / {{ interviewQuestionCount }}</h4>
                  <!-- <h2>Question</h2> -->
                  <p class="mb-2 question-title">{{ questionDetail.form.label }} <span class="qsn_required"  v-if="questionDetail.form.required ? true : false " > * </span></p>
                  <!-- <p class="type-que">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium, assumenda. What is your thought about it</p> -->
                  <div class="optionBox">
                    <input
                    type="text"
                    v-model="collabFeedbackForm.field_value"
                    :name="questionDetail.form.name"
                    :placeholder="questionDetail.form.placeholder"
                    @change="submitQuestion()"
                    v-validate="questionDetail.form.required ? 'required' : ''"
                    :data-vv-name="questionDetail.form.name"
                  />
                  </div>
                  <Loader :show-loader="isDataLoading" :isFullPage="false" />
                </div>

                <p
                  class="r-field"
                  v-show="errors.has(questionDetail.form.name)"
                  style="color: #D93F3F; font-size: 12px"
                  >This field is required</p
                >
              </div>
              <div
                class="que-input mt-4"
                v-else-if="questionDetail.form.type == 'tag'"
              >
                <div class="select-city multiple">
                  <button class="btn city multiple">Mumbai</button>
                  <button class="btn city multiple">Navi Mumbai</button>
                  <button class="btn city">Pune</button>
                  <button class="btn city">Nagpur</button>
                  <button class="btn city">Kolkata</button>
                  <button class="btn city">Vadodara</button>
                  <button class="btn city">Lakhnkow</button>
                  <button class="btn city">Coimtore</button>
                  <button class="btn city">Bengaluru</button>
                  <button class="btn city">Jaipur</button>
                  <button class="btn city">Chennai</button>
                  <button class="btn city">Bhopal</button>
                </div>
                <Loader :show-loader="isDataLoading" :isFullPage="false" />
              </div>
              <div
                class="que-input mt-4"
                v-else-if="questionDetail.form.type == 'add_feedback_reaction'"
              >
                <h4>{{ questionIndex + 1 }} / {{ interviewQuestionCount }}</h4>
                <!-- <h2>Question</h2> -->
                <p class="question-title mb-2">{{ questionDetail.form.label }} <span class="qsn_required"  v-if="questionDetail.form.required ? true : false " > * </span></p>
                <!-- <p class="type-que">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Laudantium, assumenda. What is your thought about it</p> -->
                <div class="ratenow optionBox">
                  <vue-feedback-reaction
                    v-model="feedback"
                    :labels="Reactlabels"
                    :is-active="true"
                    :value="1"
                    :width="5"
                    :height="5"
                    :emojiWidth="5"
                    :emojiHeight="5"
                    v-if="renderComponent"
                  />
                  <!-- <ReactEmoji :setEmoji="collabFeedbackForm.field_value"  :reactEmojilabels="Reactlabels" v-if="renderComponent" /> -->
                  <!-- <vue-feedback-reaction
                  :ref="'myChild' +  questionIndex + 1 "
                    v-model="collabFeedbackForm.field_value"
                    :labels="Reactlabels"
                    :is-active="true"
                    :width="5"
                    :value="1"
                    :height="5"
                    :emojiWidth="5"
                    :emojiHeight="5"/> -->

                  <!-- <img src="../assets/images/img-bad.png" class="img-bad active rate" alt=""  />
                  <img
                    src="../assets/images/img-good.png"
                    class="img-good rate"
                    alt=""
                  />
                  <img
                    src="../assets/images/img-average.png"
                    class="img-average rate"
                    alt=""
                  /> -->
                </div>
                <Loader :show-loader="isDataLoading" :isFullPage="false" />
              </div>
          </div>
        </div>
          <div class="button-st">
              <div v-if="!questionDetail.form.required">
                <button
                  class="btn btnSkip"
                  @click="submitQuestionFeedback(1)"
                >
                  Skip
                </button>
              </div>
              <div>
                <button
                    v-if="subTasks.length > 1 && questionIndex > 0"
                    class="btn btnPrevious"
                    @click="getPreviousQuestion()"
                  >
                    Previous
                  </button>
              </div>
              <button
                class="btn button-sub"
                @click="submitQuestionFeedback()"
              >
                {{ subTasks.length > 1 && ( questionIndex + 1 ) == subTasks.length ? 'Finish' : 'Next'}}
              </button>
          </div>
        </div>
        <Loader
          :show-loader="isDataLoading"
          :isFullPage="true"
          v-if="!taskDetails"
          class="vld-icon-center"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { dateFormat, dateTimeFormat } from "@/services/DateFormat.js";
import Loader from "@/components/Loader";
import { defaultTime, serverFileStoragePath } from "@/constants/consts";
import store from "@/store";
// import DateDropdown from "vue-date-dropdown";
import StarRating from "vue-star-rating";
import { VueFeedbackReaction } from "vue-feedback-reaction";
import vSelect from 'vue-select'
import Datepicker from 'vuejs-datepicker';
var checkAppStatus;
export default {
  name: "SurveySystem",
  $_veeValidate: {
    validator: "new"
  },
  components: {
    Loader,
    // DateDropdown,
    StarRating,
    VueFeedbackReaction,
    Datepicker
    // vSelect
  },
  data() {
    return {
      renderComponent: true,
      questions: [],
      questionDetail: {},
      questionIndex: 0,
      skippedQuestionCount: 0,
      startTime: "",
      isDataLoading: false,
      stopTimer: false,
      totalQuestion: 0,
      serverFileStoragePath: serverFileStoragePath,
      checkAppLiveStatus: checkAppStatus,
      taskData: "",
      taskDetails: "",
      taskName: "",
      subTasks: "",
      rating: 0,
      disabled: false,
      checkonLine: navigator.onLine ? true : false,
      collabFeedbackForm: {
        field_type: "",
        field_name: "",
        field_value: "",
        is_req: 0,
        help_text: ""
      },
      vm: "",
      datacheck: "30.06.2021",
      multiple_choice_arr: [],
      Reactlabels: ["Bad", "Disappoint", "Average", "Good", "Excellent"],
      feedback: "",
      assign_template_key: "",
      defaultDate: "",
      assignedTemplateId: "",
      setNextQsn: false,
      reaction: "natural",
      options: [
      'foo',
      'bar',
      'baz'
    ]
    };
  },
  watch: {
    checkonLine: function(val) {
      if (this.checkonLine === true) {
        this.showBackOnline = true;
        // setTimeout(()=>{ this.showBackOnline = false; }, 1000);
      } else {

      }
    },
    feedback: {
      immediate: true,
      handler(newVal, oldVal) {
        if (
          this.questionDetail.form &&
          this.questionDetail.form.type == "add_feedback_reaction"
        ) {
          this.addFeedbackReaction(this.Reactlabels[newVal - 1]);
        }
      }
    },
    helperName: function(newVal, oldVal) {
      if (this.questionDetail.form.type == "date" && newVal != oldVal) {
        this.submitQuestion();
      }
    }
  },
  computed: {
    ...mapState(["authData", "questionsVideo"]),
    ...mapGetters([
      'templateTheme',
      "loggedInUser",
      "questionVideoArr",
      "currentQuestion",
      "loading",
      "error",
      "allFeedbackFormTemplates",
      "feedbackFormQuestions",
      "questionSetWithChild",
      "interviewQuestionCount",
      "currentQuestionDetail",
      "appLivestatus"
    ]),
    totalQuestionCount: function() {
      return 0;
    },
    // helperName: function() {
    //   return this.collabFeedbackForm.field_value;
    // }
  },
  beforeMount() {
    this.questions = this.allFeedbackFormTemplates;
  },
  async mounted() {
    this.vm = this;
    const user_key = this.$route.params.user_key;
    const assign_template_key = this.$route.params.assign_template_key;
    this.assign_template_key = assign_template_key;

    if (user_key && assign_template_key) {
      try {
        this.isDataLoading = true;
        const client = await this.getClientDetail({ user_key: user_key ,assign_template_key : assign_template_key });

        if (this.loggedInUser && this.loggedInUser.id && this.error == null) {
          await this.getFeedbackFormTemplate({
            user_id: this.loggedInUser.id,
            assign_template_key: assign_template_key
          });
          const assignedTemplate = this.allFeedbackFormTemplates[0];
          this.assignedTemplateId = assignedTemplate.id;
          await this.getFeedbackFormTemplateQuestion({
            assign_id: assignedTemplate.id,
            template_id: assignedTemplate.template_id
          });

          if (
            this.error &&
            this.error.data.is_completed &&
            this.error.message != ""
          ) {
            this.$toast.open({
              message: this.error.message,
              type: "error"
            });
            this.$router.push("/invalid");
            return;
          }

          this.taskData = this.feedbackFormQuestions;
          this.taskDetails = this.taskData[0] ? this.taskData[0] : [];
          this.taskName = this.taskData[0] ? this.taskData.name : "";
          this.subTasks = this.taskDetails.sub_task
            ? this.taskDetails.sub_task
            : "";
          this.subTasks = this.subTasks.filter(x => x.type != 3);

          if (this.subTasks.length > 0) {
            this.questionDetail = this.subTasks[0];
          }
          this.setValues();
          this.isDataLoading = false;
          this.questionIndex = 0;
          this.$root.$on("set-next-question", this.setNextQuestion);
        } else {
          this.$toast.open({
            message: "Invalid user",
            type: "error"
          });
          this.isDataLoading = false;

          this.$router.push("/invalid");
          return;
        }
      } catch ($e) {
        console.log($e)
        this.$toast.open({
          message: "Invalid user",
          type: "error"
        });
        this.isDataLoading = false;
        // this.$router.push("/invalid");
        return;
      }
    } else {
      this.isDataLoading = false;
      this.$toast.open({
        message: "Invalid feedback url",
        type: "error"
      });

      // this.$router.push("/invalid");
      return;
    }
  },
  methods: {
    ...mapActions([
      "getClientDetail",
      "getFeedbackFormTemplate",
      "skipAutoQuestion",
      "getFeedbackFormTemplateQuestion",
      "addFeedbackFieldValue"
    ]),
    datepickerClosedFunction(event) {
      console.log('event')
      console.log(this.collabFeedbackForm.field_value)
    },
    forceRerender() {
      // Remove my-component from the DOM
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    async submitQuestionFeedback(is_skipped = 0) {
      const checkRequired =  is_skipped == 1 || (  this.questionDetail.form.required  && this.questionDetail.form.required  == '1' ) ? true : false
      let check_valid = await this.$validator.validateAll();
      console.log( 'this.questionDetail.form.required ', this.questionDetail.form.required ,   'checkRequired  ', checkRequired, '  check_valid  ', check_valid)
      if(checkRequired && !check_valid){
        return false
      }else {
          this.setNextQsn = true;
          const res = await this.submitQuestion(is_skipped);
          if (res) {
            this.setNextQsn = false;
          }
      }
    },
    addFeedbackReaction(val) {
      this.collabFeedbackForm.field_value = val;
      this.submitQuestion();
    },
    async submitQuestion(is_skipped = 0) {
       console.log('submitQuestion..')
      this.isDataLoading = true;
      let check_valid = await this.$validator.validateAll();
      check_valid = is_skipped ? is_skipped : check_valid;
      if (this.questionDetail.form.type == "multiple_choice") {
        this.collabFeedbackForm.field_value = this.multiple_choice_arr;
      }
      if (this.questionDetail.form.type == "rating_1_to_5") {
        this.collabFeedbackForm.field_value = this.rating;
      }
      if (this.questionDetail.form.type == "date") {
        this.collabFeedbackForm.field_value = dateTimeFormat(
          this.collabFeedbackForm.field_value
        );
      }
      if (check_valid) {
        const formData = {
          form_field: this.questionDetail.form.name,
          assign_template: this.assignedTemplateId,
          task: this.questionDetail.task,
          sub_task: this.questionDetail.id,
          value: this.collabFeedbackForm.field_value,
          user_id: this.loggedInUser.id,
          is_skipped: is_skipped ? 1 : 0,
          assign_template_key: this.assign_template_key
        };
        this.isDataLoading = true;
        const res = await this.addFeedbackFieldValue(formData).then(res => {
          this.subTasks[this.questionIndex].process_value[0] = res;
          this.isDataLoading = false;
          if (this.setNextQsn) {
            this.setNextQuestion();
          }
        });
        return res;
      } else {
        this.isDataLoading = false;
      }
    },
    async setNextQuestion() {
      this.resetForm();
      this.forceRerender();
      this.setNextQsn = false;
      const data = this.subTasks[this.questionIndex + 1];
      if (data) {
        this.questionDetail = data;
        this.questionIndex = this.questionIndex + 1;
        this.setValues();
        this.isDataLoading = false;
      } else {
        this.$router.push("/thankyou");
      }
    },
    getPreviousQuestion() {
      this.resetForm();
      this.forceRerender();
      this.setNextQsn = false;
      const data = this.subTasks[this.questionIndex - 1];
      if (data) {
        this.questionDetail = data;
        this.questionIndex = this.questionIndex - 1;

        this.setValues();
        this.isDataLoading = false;
      }
    },
    setRating(rating) {
      this.rating = rating;
      this.submitQuestion();
    },
    setValues() {
      this.defaultDate = "";
      if (
        this.questionDetail.process_value.length > 0 &&
        this.questionDetail.process_value[0].value
      ) {
        if (this.questionDetail.form.type == "multiple_choice") {
          this.multiple_choice_arr = this.questionDetail.process_value[0].value;
        } else if (this.questionDetail.form.type == "rating_1_to_5") {
          this.rating = this.questionDetail.process_value[0].value;
        } else if (this.questionDetail.form.type == "add_feedback_reaction") {
          const fval =
            this.Reactlabels.indexOf(
              this.questionDetail.process_value[0].value
            ) + 1;
          this.feedback = String(fval);
        } else if (this.questionDetail.form.type == "date") {
          this.defaultDate = dateFormat(
            this.questionDetail.process_value[0].value
          );
        } else {
          this.collabFeedbackForm.field_value = this.questionDetail.process_value[0].value;
        }
      }
    },
    resetForm() {
      this.rating = 0;
      this.feedback = "";
      this.collabFeedbackForm = {
        field_type: "",
        field_name: "",
        field_value: "",
        is_req: 0,
        help_text: ""
      };
    },
    skipQuestions() {
      const isSkipped = 1;
      this.submitQuestionFeedback(isSkipped);
    },
    makeid(length = 15) {
      var result = "";
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      const testetest = new Date();
      return testetest;
    }
  }
};
</script>
